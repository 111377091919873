<template>
    <div class="hello">
      <img class="hero-img" alt="Vue logo" src="../assets/sahaneler_logo_horizontal.png">
      <div class="buttons-container">
        <a class="link-button" href="https://www.shopier.com/sahaneler">Mağazamızı ziyaret et (Store)</a>
        <a class="link-button" href="https://www.instagram.com/sahaneler/"> Instagram: @sahaneler</a>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {}
</script>
<style>
.hero-img{
  max-width: 300px; height: auto; width: 100%; padding-bottom:20px
}
.hello{
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
  text-decoration: none;
}
.buttons-container{
  display: flex;
  flex-direction: row;
  flex-wrap:nowrap;
  width: 100%;
  justify-content: center;
}
.link-button{
  border:1px solid #42b983;
  padding:15px;
  border-radius: 10px;
  margin: 10px;
  font-weight: 600;
}
</style>
